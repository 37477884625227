<template>
  <main class="kb-main" id="kb-hrdcloud" @click="closeToggles(toggles)">
    <lxp-main-header :show-breadcrumb="true" :show-custom-title="true" :show-excel-download="false" :title="`학습인증 챌린지`" >

    </lxp-main-header>

    <div class="main-content main-component">
      <div class="kb-footer" id="kb-footer">
        <div v-if="session.lrnerId" class="footer-row footer-divider" style="border-top:0px;">
          <div class="footer-notice footer-component">
            <ul class="notice-list swiper-wrapper" style="width: 80%;">
              <swiper
                  :slides-per-view="1"
                  :modules="modules"
                  :navigation="{
                nextEl: '.btn-notice-nav-next',
                prevEl: '.btn-notice-nav-prev'
              }"
              >
                <swiper-slide v-for="(item, idx) in items" :key="idx" class="notice-item swiper-slide"
                              style="margin: 0;">
                  <div class="notice-date">
                    <a href="javascript:" class="link">{{ item.regDt }}</a>
                  </div>
                  <div class="notice-title">
                    <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="link">
                      {{ item.ntcTitle }}
                    </router-link>
                  </div>
                </swiper-slide>
                <!--            </swiper-slide>-->
              </swiper>
            </ul>

          </div>
        </div>
      </div>

      <template v-if="tabMenu <= 0">

        <div class="intro-section" style="padding-bottom: 20px; border-bottom:1px solid var(--kb-border-color);">

          <div class="kb-main" id = "kb-sdl-ssm">
            <nav class="kb-nav-round-tabs">
              <ul class="kb-nav-list">
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu ==1 }" @click="menu=1"><span class="kb-nav-text">파생상품</span></a>
                </li>
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu==2 }" @click="menu=2"><span class="kb-nav-text">SQLD</span></a>
                </li>
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu==3 }" @click="menu=3"><span class="kb-nav-text">신용분석사</span></a>
                </li>
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu==4 }" @click="menu=4"><span class="kb-nav-text">펀드</span></a>
                </li>
              </ul>
            </nav>
            <div v-if="menu==1" class="main-content min-component" style="padding-bottom: 20px;">
              <BoardReadOnly
                  :board-id="boardId[0]"
              />
            </div>
            <div v-if="menu==2" class="main-content min-component" style="padding-bottom: 20px;">
              <BoardReadOnly
                  :board-id="boardId[1]"
              />
            </div>
            <div v-if="menu==3" class="main-content min-component" style="padding-bottom: 20px;">
              <BoardReadOnly
                  :board-id="boardId[2]"
              />
            </div>
            <div v-if="menu==4" class="main-content min-component" style="padding-bottom: 20px;">
              <BoardReadOnly
                  :board-id="boardId[3]"
              />
            </div>
          </div>


        </div>

      </template>

      <!--    <HrdCloudMap2 v-model="cloudMapModal"/>-->

      <section class="hrdcloud section">
        <template v-for="(item, idx) in mainList" :key="idx">
          <article class="content-section"  v-if="idx === menu-1">
            <header class="section-header"><h3 class="title">{{ item.title }}</h3>
              <div class="header-sub">
                <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001', ...item.params }}" class="more-link">전체보기</router-link>
              </div>
            </header>
            <template v-if="item.items && item.items.length > 0 ">
              <HrdCloudRow v-if="!item.isLoading" :items="item.items" :show-put="true" :session="session"/>
            </template>
            <template v-else-if="(item.items && item.items.length === 0) && !item.isLoading">
              <div class="search-container">
                <div class="result-empty">
                  <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p  class="text">콘텐츠가 없습니다.</p>
                </div>
              </div>
            </template>
            <template v-else-if="item.isLoading">
              <LoadingDiv v-model="item.isLoading" />
            </template>
          </article>
        </template>
      </section>


    </div>

  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {
  closeToggles,
  getDcdOptions, pushQuery,
} from '@/assets/js/ui.init';
import {
  initProgressBar,
  timestampToDateFormat
} from '@/assets/js/util';
import { setMainItems} from '@/assets/js/modules/hrd/hrd-common';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {
  ACT_GET_HRDCLOUD_LIST
} from '@/store/modules/prep/prep';
import {
  banerExpsrLocCdDcds,
} from '@/assets/js/modules/baner/common-baner';
import {Pagination} from "swiper";
import LxpMainHeader from '@/components/common/LxpMainHeader';
import BoardReadOnly from '@/components/board/BoardReadOnly'
import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
import LoadingDiv from '@/components/common/LoadingDiv';
import {useRoute} from "vue-router/dist/vue-router";
const hrdLoading = ref(false);


export default {
  name: 'LrnCertChall',
  components: {
    BoardReadOnly,
    // CommonBanner,
    Swiper,
    SwiperSlide,
    LxpMainHeader,
    LoadingDiv,
    HrdCloudRow,
    // ProfileImg,
  },
  setup() {
    initProgressBar();
    const boardId = reactive([
      'lrn-cert-drvt',
      'lrn-cert-sqld',
      'lrn-cert-cca',
      'lrn-cert-fund',
    ]);

    const route = useRoute();
    const store = useStore();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.bpcc);
    const cloudMapModal = ref(false);
    const items = ref([]);
    const mainList = ref([
      { title: '파생상품투자권유자문인력 콘텐츠 ', params: {category1:1 , category2: 13, category3:1876}, isLoading: true, items:[] },
      { title: 'SQLD 콘텐츠', params: {category1:1 , category2: 13, category3:1886}, isLoading: true, items:[] },
      { title: '신용분석사 콘텐츠', params: {category1:1 , category2: 13, category3:1877}, isLoading: true, items:[] },
      { title: '펀드투자권유자문인력 콘텐츠', params: {category1:1 , category2: 13,  category3:1874}, isLoading: true, items:[] },
    ]);
    const isLoading = ref(false);

    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));

    // 순서를 변경할때 getMainList의 idx값도 같이 바꿔줘야함

    // const currentTs = ref(new Date().getTime());
    const menu = ref(route.query.tab || 1);
    const myLikeTotalCnt = ref('0');

    const hrds = ref([]);
    const categories = ref([]);
    const tubePaging = reactive({
      pageNo: 1,
      pageSize: 4,
      totalCount: 0
    });

    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });
    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    const getMainList = async (idx) => {
      if(idx === '' || idx === undefined){
        idx = 0;
      }
      let cont = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`, {...mainList.value[idx].params, ...tubePaging});
      setMainItems(mainList, cont, idx);
    };


    // getListFunc(`learn/${ACT_GET_SUMGO_LEARNING_LIVE_LIST}`, {}, items_live, null, () => {
    // });



    watch(() => menu.value,  () => {

      console.log("WATCH menu.value", menu.value);

      getMainList(menu.value -1);
      pushQuery({tab : menu.value});

    })

    // watch(() => route.query.tab,  () => {
    //   if(menu.value !== route.query.tab){
    //     menu.value = route.query.tab;
    //   }
    // })


    onMounted(()=>{
      getMainList(menu.value -1);
    });

    return {
      isLoading, session, sortTypeDcd, hrds,   cloudMapModal, lochref,
      tabMenu, params, categories,
      mainList,
      toggles, closeToggles,

      modules: [Pagination],
      items,
      // items_tube,
      // items_board,
      // items_live,
      // weekly_board_first,
      // weekly_board_second,
      menu,
      myLikeTotalCnt,
      // board_first,
      // board_second,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
      boardId,

    }
  }
};
</script>